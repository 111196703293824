import {
    trigger,
    state,
    style,
    animate,
    transition,
    query,
    animateChild,
    group
} from '@angular/animations';

export const slideInAnimation =
    trigger('routeAnimations', [
        transition('IntroPage => *', [
            style({ position: 'relative' }),
            query(':leave', [
                style({
                    position: 'relative',
                    top: 0,
                    left: 0,
                    height: '100%'
                })
            ]),
            query(':leave', animateChild()),
            group([
                query(':leave', [
                    animate('1000ms ease-in-out', style({ transform: 'scale(0)', opacity: 0.2}))
                ])
            ])
        ])
    ]);